import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import './HomeStyles.css';
import Unpacking from '../assets/Unpack_01.mp4';
import Scan01 from '../assets/Scan_01.mp4';
import Scan02 from '../assets/Scan_02.mp4';
import Scan03 from '../assets/Scan_03.mp4';
import Pack01 from '../assets/Pack_01.mp4';
import Pack02 from '../assets/Pack_02.mp4';
import Pack03 from '../assets/Pack_03.mp4';
import Pack04 from '../assets/Pack_04.mp4';
import Pack05 from '../assets/Pack_05.mp4';
import Pack06 from '../assets/Pack_06.mp4';
import Prepare01 from '../assets/Prepare_01.mp4';
import Prepare02 from '../assets/Prepare_02.mp4';
import Prepare03 from '../assets/Prepare_03.mp4';
import Prepare04 from '../assets/Prepare_04.mp4';
import Prepare05 from '../assets/Prepare_05.mp4';
import Prepare06 from '../assets/Prepare_06.mp4';
import Prepare07 from '../assets/Prepare_07.mp4';
import Prepare08 from '../assets/Prepare_08.mp4';
import { Accordion, Tab, Tabs, Breadcrumb } from 'react-bootstrap';

const AccordionComp = () => {
	return (
		<>
			<Header />
			<main class="accordion-page">
				<div class="wrapper" style={{ display: 'block', padding: '0 2%', height: '84vh', top: '140px' }}>
					<Breadcrumb>
						<Breadcrumb.Item href="/">Home</Breadcrumb.Item>
						<Breadcrumb.Item href="/">Training Videos</Breadcrumb.Item>
						<Breadcrumb.Item active>Packing Procedure</Breadcrumb.Item>
					</Breadcrumb>
					<Tabs defaultActiveKey="Unpacking" className="mb-3">
						<Tab eventKey="Unpacking" title="Unpacking">
							<Accordion defaultActiveKey={['0']} style={{ height: '70vh' }}>
								<Accordion.Item eventKey="0">
									<Accordion.Header>Step 1: Unpacking the Shipment</Accordion.Header>
									<Accordion.Body style={{}}>
										<iframe style={{ height: '400px' }} class="video-window" width="30%" height="400px" src={Unpacking} title="YouTube video player" frameborder="0" allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
										<ul class="step-list">
											<li>Remove package shipping slip.</li>
											<li>Move package to worktable.</li>
											<li>Remove packing tape to separate individual packages.</li>
										</ul>
									</Accordion.Body>
								</Accordion.Item>
							</Accordion>
						</Tab>

						<Tab eventKey="Scanning" title="Scanning">
							<Accordion defaultActiveKey={['0']} style={{ height: '70vh' }}>
								<Accordion.Item eventKey="0">
									<Accordion.Header>Step 1: Verifying Part Number</Accordion.Header>
									<Accordion.Body style={{}}>
										<iframe style={{ height: '400px' }} class="video-window" width="30%" height="400px" src={Scan01} title="YouTube video player" frameborder="0" allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
										<ul class="step-list">
											<li>Check each package to verify the part number is correct to pick ticket.</li>
										</ul>
									</Accordion.Body>
								</Accordion.Item>

								<Accordion.Item eventKey="1">
									<Accordion.Header>Step 2: Scanning Packages into the System</Accordion.Header>
									<Accordion.Body style={{}}>
										<iframe style={{ height: '400px' }} class="video-window" width="30%" height="400px" src={Scan02} title="YouTube video player" frameborder="0" allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
										<ul class="step-list">
											<li>Use Material Barcode hand scanner to scan each package and press the Enter button on the keypad.</li>
										</ul>
									</Accordion.Body>
								</Accordion.Item>

								<Accordion.Item eventKey="2">
									<Accordion.Header>Step 3: Verifying Part Numbers</Accordion.Header>
									<Accordion.Body style={{}}>
										<iframe style={{ height: '400px' }} class="video-window" width="30%" height="400px" src={Scan03} title="YouTube video player" frameborder="0" allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
										<ul class="step-list">
											<li>Make sure that there are any part numbers indicating that they are to stay</li>
										</ul>
									</Accordion.Body>
								</Accordion.Item>
							</Accordion>
						</Tab>

						<Tab eventKey="Packing" title="Packing">
							<Accordion defaultActiveKey={['0']} style={{ height: '70vh' }}>
								<Accordion.Item eventKey="0">
									<Accordion.Header>Step 1: Retrieving/Assembling Shipping Box</Accordion.Header>
									<Accordion.Body style={{}}>
										<iframe style={{ height: '400px' }} class="video-window" width="30%" height="400px" src={Pack01} title="YouTube video player" frameborder="0" allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
										<ul class="step-list">
											<li>Retrieve and assemble an appropriately-sized shipping box with packing tape.</li>
										</ul>
									</Accordion.Body>
								</Accordion.Item>

								<Accordion.Item eventKey="1">
									<Accordion.Header>Step 2: Verifying SPI Unit Container</Accordion.Header>
									<Accordion.Body style={{}}>
										<iframe style={{ height: '400px' }} class="video-window" width="30%" height="400px" src={Pack02} title="YouTube video player" frameborder="0" allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
										<ul class="step-list">
											<li>Verify SPI unit container (P140AP140 is only for labeling each item and thy do have a label on).</li>
										</ul>
									</Accordion.Body>
								</Accordion.Item>

								<Accordion.Item eventKey="2">
									<Accordion.Header>Step 3: Printing Package Labels</Accordion.Header>
									<Accordion.Body style={{}}>
										<iframe style={{ height: '400px' }} class="video-window" width="30%" height="400px" src={Pack03} title="YouTube video player" frameborder="0" allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
										<ul class="step-list">
											<li>Print out individual package labels.</li>
										</ul>
									</Accordion.Body>
								</Accordion.Item>
								<Accordion.Item eventKey="3">
									<Accordion.Header>Step 4: Labeling Packages</Accordion.Header>
									<Accordion.Body style={{}}>
										<iframe style={{ height: '400px' }} class="video-window" width="30%" height="400px" src={Pack04} title="YouTube video player" frameborder="0" allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
										<ul class="step-list">
											<li>Place individual label on each inner package (do not cover existing label).</li>
										</ul>
									</Accordion.Body>
								</Accordion.Item>
								<Accordion.Item eventKey="4">
									<Accordion.Header>Step 5: Loading Packages</Accordion.Header>
									<Accordion.Body style={{}}>
										<iframe style={{ height: '400px' }} class="video-window" width="30%" height="400px" src={Pack05} title="YouTube video player" frameborder="0" allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
										<ul class="step-list">
											<li>Place packages into shipping container (make sure each is sealed correctly).</li>
										</ul>
									</Accordion.Body>
								</Accordion.Item>
								<Accordion.Item eventKey="5">
									<Accordion.Header>Step 6: Securing Packages</Accordion.Header>
									<Accordion.Body style={{}}>
										<iframe style={{ height: '400px' }} class="video-window" width="30%" height="400px" src={Pack06} title="YouTube video player" frameborder="0" allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
										<ul class="step-list">
											<li>Insert packaging dunnage around packages to prevent movement.</li>
										</ul>
									</Accordion.Body>
								</Accordion.Item>
							</Accordion>
						</Tab>

						<Tab eventKey="Preparing" title="Preparing">
							<Accordion defaultActiveKey={['0']} style={{ height: '70vh' }}>
								<Accordion.Item eventKey="0">
									<Accordion.Header>Step 1: Entering Spare Parts Information</Accordion.Header>
									<Accordion.Body style={{}}>
										<iframe style={{ height: '400px' }} class="video-window" width="30%" height="400px" src={Prepare01} title="YouTube video player" frameborder="0" allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
										<ul class="step-list">
											<li>Enter information in Spare Parts Packing screen.</li>
										</ul>
									</Accordion.Body>
								</Accordion.Item>
								<Accordion.Item eventKey="1">
									<Accordion.Header>Step 2: Printing Shipping Labels and Paperwork</Accordion.Header>
									<Accordion.Body style={{}}>
										<iframe style={{ height: '400px' }} class="video-window" width="30%" height="400px" src={Prepare02} title="YouTube video player" frameborder="0" allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
										<ul class="step-list">
											<li>Print out shipping labels and shipping list paperwork.</li>
										</ul>
									</Accordion.Body>
								</Accordion.Item>
								<Accordion.Item eventKey="2">
									<Accordion.Header>Step 3: Placing Green Sleeve Shipping Slip</Accordion.Header>
									<Accordion.Body style={{}}>
										<iframe style={{ height: '400px' }} class="video-window" width="30%" height="400px" src={Prepare03} title="YouTube video player" frameborder="0" allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
										<ul class="step-list">
											<li>Place one shipping slip inside green label sleeve and place on the inside of box.</li>
										</ul>
									</Accordion.Body>
								</Accordion.Item>
								<Accordion.Item eventKey="3">
									<Accordion.Header>Step 4: Placing Clear Sleeve Shipping Slip</Accordion.Header>
									<Accordion.Body style={{}}>
										<iframe style={{ height: '400px' }} class="video-window" width="30%" height="400px" src={Prepare04} title="YouTube video player" frameborder="0" allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
										<ul class="step-list">
											<li>The other copy along with shipping label is placed inside clear label sleeve.</li>
										</ul>
									</Accordion.Body>
								</Accordion.Item>
								<Accordion.Item eventKey="4">
									<Accordion.Header>Step 5: Placing Back Shipping Label</Accordion.Header>
									<Accordion.Body style={{}}>
										<iframe style={{ height: '400px' }} class="video-window" width="30%" height="400px" src={Prepare05} title="YouTube video player" frameborder="0" allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
										<ul class="step-list">
											<li>Place back shipping label (1) on outer shipping container.</li>
										</ul>
									</Accordion.Body>
								</Accordion.Item>
								<Accordion.Item eventKey="5">
									<Accordion.Header>Step 6: Placing Front Shipping Labels</Accordion.Header>
									<Accordion.Body style={{}}>
										<iframe style={{ height: '400px' }} class="video-window" width="30%" height="400px" src={Prepare06} title="YouTube video player" frameborder="0" allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
										<ul class="step-list">
											<li>Place front shipping labels (2) on either side of the shipping container.</li>
										</ul>
									</Accordion.Body>
								</Accordion.Item>
								<Accordion.Item eventKey="6">
									<Accordion.Header>Step 7: Sealing Container</Accordion.Header>
									<Accordion.Body style={{}}>
										<iframe style={{ height: '400px' }} class="video-window" width="30%" height="400px" src={Prepare07} title="YouTube video player" frameborder="0" allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
										<ul class="step-list">
											<li>Seal box with packing tape.</li>
										</ul>
									</Accordion.Body>
								</Accordion.Item>
								<Accordion.Item eventKey="7">
									<Accordion.Header>Step 8: Placing Final Label</Accordion.Header>
									<Accordion.Body style={{}}>
										<iframe style={{ height: '400px' }} class="video-window" width="30%" height="400px" src={Prepare08} title="YouTube video player" frameborder="0" allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
										<ul class="step-list">
											<li>Place shipping label on top and seal label sleeve.</li>
										</ul>
									</Accordion.Body>
								</Accordion.Item>
							</Accordion>
						</Tab>
					</Tabs>
				</div>
				<Footer />
			</main>
		</>
	);
};

export default AccordionComp;
