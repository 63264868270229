import 'bootstrap/dist/css/bootstrap.min.css';
import './FooterStyles.css';
import logo from '../../assets/its-logo.png';

const Footer = () => {
	return (
		<footer>
			<div class="footer-left">
				<p>Powered By:</p>
				<a class="footer-logo" href="http://www.its-na.com/">
					<img src={logo} alt="logo" />
				</a>
			</div>

			<div class="footer-right">
				<button href="" class="plain-button" target="_self" onclick="opencontactmodal();">
					<i class="fa fa-phone" aria-hidden="true"></i> <span class="plain-button-copy">Contact Us</span>
				</button>
			</div>
		</footer>
	);
};
export default Footer;
