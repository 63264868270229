import { Route, Routes } from 'react-router-dom';

import Home from './routes/Home';
import Accordion from './routes/Accordion';
import Carousel from './routes/Carousel';

function App() {
	return (
		<>
			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="/accordion" element={<Accordion />} />
				<Route path="/carousel" element={<Carousel />} />
			</Routes>
		</>
	);
}

export default App;
