import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import { Breadcrumb } from 'react-bootstrap';
import FullVideo from '../assets/full-video.mp4';
import { TbStairs } from 'react-icons/tb';
import './HomeStyles.css';

import React from 'react';

const Home = () => {
	return (
		<>
			<Header />

			<main class="home" style={{}}>
				<Breadcrumb style={{ position: 'relative', top: '18%', left: '2%' }}>
					<Breadcrumb.Item href="/">Home</Breadcrumb.Item>
					<Breadcrumb.Item href="/">Training Videos</Breadcrumb.Item>
				</Breadcrumb>
				<div class="wrapper" style={{}}>
					<div class="link-container">
						<a href="/">
							<div class="main-link">
								<iframe class="video-window" style={{ margin: 0, height: '100%', width: '100%' }} width="100%" height="500px" src={FullVideo} title="YouTube video player" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
							</div>
							<h3 style={{ textAlign: 'center' }}>Full Procedure</h3>
						</a>
					</div>
					<div class="link-container">
						<a href="/accordion">
							<div class="main-link">
								<TbStairs size={100} style={{ height: '100%', width: '100%' }} />
							</div>
							<h3 style={{ textAlign: 'center' }}>Step-by-Step Procedure</h3>
						</a>
					</div>
					{/* <div class="link-container">
						<a href="/carousel">
							<div class="main-link">XXX</div>
							<h3 style={{ textAlign: 'center' }}>Carousel SBS</h3>
						</a>
					</div> */}
				</div>
				<Footer />
			</main>
		</>
	);
};

export default Home;
