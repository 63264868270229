import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import './HomeStyles.css';

import { Carousel } from 'react-bootstrap';
import background from '../assets/france.jpg';

import React from 'react';

const CarouselComp = () => {
	return (
		<>
			<Header />
			<main class="carousel-page">
				<div class="wrapper">
					<Carousel controls slide={true} fade={true}>
						<Carousel.Item>
							<img className="" src={background} alt="First slide" />
							<Carousel.Caption>
								<h3>Step One</h3>
								<p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
							</Carousel.Caption>
						</Carousel.Item>
						<Carousel.Item>
							<img className="" src={background} alt="Second slide" style={{ filter: 'grayscale(1.0)' }} />

							<Carousel.Caption>
								<h3>Step Two</h3>
								<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
							</Carousel.Caption>
						</Carousel.Item>
						<Carousel.Item>
							<img className="" src={background} alt="Third slide" />

							<Carousel.Caption>
								<h3>Step Three</h3>
								<p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
							</Carousel.Caption>
						</Carousel.Item>
					</Carousel>
				</div>
			</main>
			<Footer />
		</>
	);
};

export default CarouselComp;
