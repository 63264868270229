import { useState } from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
import './HeaderStyles.css';
import logo from '../../assets/icon.png';
import { FaSearch } from 'react-icons/fa';

const Header = () => {
	return (
		<header className="header">
			<div className="header-left">
				<a className="header-logo mb-md-0 me-md-auto" href="/">
					<img src={logo} alt="logo" />
				</a>
			</div>

			<div className="header-right" style={{}}>
				<h1>PRATT & WHITNEY QuickResponse&trade; Library</h1>
				<div class="search-bar" style={{ display: 'flex' }}>
					<FaSearch size={20} style={{ color: '#000', margin: 'auto 10px' }} />
					<input style={{ width: '90%', height: '100%', margin: 'auto 0', borderRadius: '1rem', padding: '.5rem' }} />
				</div>
			</div>
		</header>
	);
};

export default Header;
